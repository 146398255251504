<template>
    <agent />
</template>

<script>
// @ is an alias to /src
import agent from "@/components/agent";
export default {
  name: "agent_apply",
  components: {
    agent,
  }
};
</script>