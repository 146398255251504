<template>
  <div class="apply-page">
    <topbar2></topbar2>
    <div class="body-bg">
      <div class="center-body">
        <div class="settop">
          <span class="first">1</span>
          <p class="first_text">签署开放充值平台入驻协议</p>
          <p class="preline"></p>
          <span class="second">2</span>
          <p class="second_text">入驻平台成功</p>
        </div>
        <div class="agreement">
          <p>开放充值平台购买方服务协议</p>
          <pre>
             <span>诚美优选开放充值平台购买方服务协议</span>
             诚美优选虚拟充值开放平台代理商入驻协议如下：
               免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如果您对本协议的任何条款表示异议，或者无法准
             确理解本协议，请不要使用虚拟充值开放平台代理商服务。您使服务等行为视为对本协议全部内容的接受，也意味
             您将接受本协议以及未来针对本协议更新版本条款的约束，并服从虚拟充值开放平台统一管理。
             一：协议服务程序
                 虚拟充值平台是诚美优选旗下向用户开放的以虚拟商品类型的虚拟商品。用户可以进行申请 代理商角色，审
                 核通过后，可在平台进行同步商品到诚美店铺。
             二：充值服务
             1、虚拟充值平台，在供应商下架或删除货源时，代理商同步的货源会不展示，平台会以短信的方式通知代理商。代
             理商可以根据自己的需求选择其他的货源进行同步。
             2、代理商退出虚拟充值需要满足平台的相关要求，在通过平台要求后，可以申请退店申请。平台审核通过后，可以
             完成退出虚拟充值平台。如果再次想申请加入时，需要等30天后，才能再次进行申请。
            </pre>
            <span type="danger" size="large" class="nextbtn" @click="step">同意协议并进行下一步</span>
        </div>
      </div>
    </div>
    <!-- 导航栏 -->
  </div>
</template>

<script>
import Topbar2 from "@/components/topbar2";
export default {
  name: "agent",
  // inject:['app'],
  components: {
    Topbar2
  },
  methods: {
    step() {
      var that=this;
      let params={'store_name':this.$store.state.user.store_name,'did':this.$store.state.user.p_id}
      $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/Agent',
          data:params,
          dataType:'json',
          success:function(res){
              that.$notify({
          title: '提示',
          position: 'top-left',
          type: 'warning',
          message: '您的交易密码是'+res.data+'请牢记您的密码！',
          duration: 0
        });
          },
        })
      this.$router.push("./agent_finish");
    }
  }
};
</script>

<style lang="less" scoped>
.apply-page {
  position: relative;
  padding: 0px;
  margin: 0;
  width: 100%;
  height: 100%;
  //导航栏
  .body-bg {
    width: 100%;
    height: 1000px;
    position: absolute;
    top: 80px;
    padding-top: 20px;
    background: rgba(242, 243, 250, 1);
  }
  .center-body {
    width: 1300px;
    height: 98%;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(222, 222, 222, 1);
    border-radius: 8px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .settop {
      height: 40px;
      width: 750px;
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      display: inline-block;
      margin: 0 auto;
      > span {
        display: inline-block;
      }
      > p {
        display: inline-block;
      }
      .first {
        width: 36px;
        height: 36px;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border: 2px solid rgba(222, 222, 222, 1);
        border-radius: 50%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(61, 49, 226, 1);
        line-height: 32px;
        margin-left: 18px;
      }
      .first_text {
        height: 20px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(55, 67, 214, 1);
        line-height: 26px;
        margin: 0;
        margin-left: 18px;
      }
      .second {
        width: 36px;
        height: 36px;
        text-align: center;
        background: rgba(204, 204, 204, 1);
        border-radius: 50%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 36px;
        margin-left: 18px;
      }
      .second_text {
        height: 20px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 26px;
        margin: 0;
        margin-left: 18px;
      }
      .preline {
        width: 200px;
        height: 4px;
        background: rgba(204, 204, 204, 1);
        border-radius: 1px;
        margin-left: 18px;
        margin-bottom: 5px;
      }
    }
    .agreement {
      display: inline-block;
      width: 800px;
      height: 680px;
      position: absolute;
      top: 200px;
      left: 0;
      right: 0;
      display: inline-block;
      margin: 0 auto;
      border: 1px solid rgba(204, 204, 204, 1);
      font-size: 14px;
      line-height: 36px;
      >p{font-size: 22px;line-height: 37px;text-align: center;font-family:Microsoft YaHei;color:rgba(48,48,48,1);margin-top: 20px;}
      >pre{margin-top: 20px;font-family:Microsoft YaHei;color:rgba(48,48,48,1);
      >span{font-size: 16px;}}
    }
    .nextbtn {
      width: 360px;
      height: 60px;
      background: rgba(55, 67, 214, 1);
      border-radius: 30px;
      display: inline-block;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 60px;
      text-align: center;
      margin-left: 200px;
      cursor: pointer;
    }
  }
}
</style>